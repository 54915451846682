:root {
    --send-message-background-color: #e0ffe0;
    --received-message-background-color: whitesmoke;
}

.Message {
    max-width: 80%;
    margin: 10px;
    border-radius: 10px;
    padding: 7px;
    color: black;
}

.Header {
    margin-top:0px; 
    font-size: 12px;
}

.eingang {
    justify-items: flex-start;
    background-color: var(--received-message-background-color);
    margin-right: 20%;
    text-align: left;
}

.ausgang {
    justify-items: flex-end;
    background-color: var(--send-message-background-color);
    margin-left: 20%;
    text-align: right;
}
